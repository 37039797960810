<template>
  <div class="mock-record" v-if="info">

    <el-card>
      <el-page-header slot="header" @back="goBack">
        <div slot="content" class="flex flex-between flex-v-center flex1">
          <div>
            <div class="flex flex-v-center">
              <h4 class="margin-r">{{ info.mock.flag == 1 ? ('【' + $t('VIP') + '】') : ('【' + $t('免费') + '】') }}{{
                info.mock.title }}
                <span style="margin-left: 10px;">- {{ $t('答案') }}
                  & {{ $t('评分详情') }}</span>
              </h4>

            </div>

          </div>

          <div class="flex flex-v-center right">
            <el-button @click="evaluate()" icon="el-icon-thumb" v-if="info.status == 1" type="primary" round>{{
              $t('申请打分')
              }}</el-button>
            <el-button disabled v-if="info.status == 2" type="primary" round>{{ $t('智能打分中') }}...</el-button>


            <el-button v-if="info.status == 3 && info.user_id == $store.state.user.id" round plain class="margin-r"
              size="small" @click="share()" type="primary">
              <div class="flex flex-v-center"><i class="el-icon-share" style="font-size:18px;"></i>
                <h3>{{ $t('分享') }}</h3>
              </div>
            </el-button>


            <el-button round v-if="info.user_id == $store.state.user.id" size="small" plain @click="remove()"
              type="primary">
              <div class="flex flex-v-center"><i class="el-icon-close" style="font-size:18px;"></i>
                <h3>{{ $t('删除') }}</h3>
              </div>
            </el-button>
          </div>
        </div>

      </el-page-header>

      <el-tabs v-if="info.status == 3" v-model="selectedTab">
        <el-tab-pane label="Score Report" name="0">
          <div id="chart" style="width:800px;height: 300px;margin: 20px 0"></div>
        </el-tab-pane>
        <el-tab-pane v-for="(sort, index) in sorts " :key="index" :label="sort" :name="(index + 1).toString()">
          <el-table :data="tableDatas[index]" stripe style="width: 100%">
            <el-table-column prop="sort" :label="$t('题型类型')" width="180">
            </el-table-column>
            <el-table-column prop="type" :label="$t('题型')" width="180">
            </el-table-column>
            <el-table-column prop="weight" :label="$t('权重')">
            </el-table-column>

            <el-table-column prop="score" :label="$t('贡献分数')">
            </el-table-column>

            <el-table-column prop="rate" :label="$t('正确率')">
              <template slot-scope="scope">
                <el-progress
                  :percentage="Number((scope.row.score.split('/')[0] / scope.row.score.split('/')[1] * 100).toFixed(0))"></el-progress>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

      <el-divider v-if="info.status == 3"></el-divider>

      <el-tabs v-model="selectedTab1">
        <el-tab-pane
          :label="$t('口语') + '(' + info.items.filter((val) => { return val.sort == 'Speaking' }).length + ')'"
          name="Speaking">
          <div class="padding-x"
            v-for="( item, index ) in info.items.filter((val) => { return val.sort == 'Speaking' }) " :key="index">
            <div class="flex flex-between flex-v-center">
              <div class="flex flex-v-center">
                <h4>{{ index + 1 }}.{{ item.tag }} #{{ item.no }} {{ ['RS', 'ASQ'].indexOf(item.tag) != -1 ? '' :
                  item.title }}
                </h4>
                <div v-if="info.status == 3 && (item.tag == 'RS' || item.tag == 'RL' || item.tag == 'ASQ')"
                  class="margin-l flex flex-v-center">
                  <el-tag size="mini" effect="dark" style="margin-right: 10px;">{{ $t("原音频") }}</el-tag>
                  <wave-player :src="$host + '/api/audio/' + item.no + '/Origin'"></wave-player>
                </div>
              </div>
              <el-link size="small" @click="toWork(item)" type="primary" class="margin-x">{{ $t('去练习') }}<i
                  class="el-icon-right"></i></el-link>
            </div>


            <div v-if="info.status == 3">

              <div class="margin-t" :class="{ 'flex': ['RL', 'RS', 'ASQ', 'RA'].indexOf(item.tag) == -1 }">
                <div v-if="['RL', 'RS', 'ASQ', 'RA'].indexOf(item.tag) != -1" class="mt bold">{{ $t("音频文本") }}:</div>
                <div :class="{ 'flex': item.tag == 'ASQ' }">
                  <div class="word-mock ">{{ item.words_en }}</div>
                  <div v-if="item.tag == 'ASQ'" class="word-mock margin-l"><span class="mt bold">{{ $t('答案')
                      }}</span>：{{
                        item.answer }}</div>
                </div>
              </div>
              <div v-if="item.tag == 'DI'" class="flex">
                <el-image fit="contain" style="width: 400px;" ref="image" @click="preview"
                  :preview-src-list="[imageUrl(item.no)]" :src="imageUrl(item.no)"></el-image>

                <div class="margin-l">
                  <div v-for="( row, index ) in item.analysis.split('\n') "
                    v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(item.tag)[2] + '：</h4><div>' + getHtml(item, row, 1) + '</div>' : getHtml(item, row, 1) ? getHtml(item, row, 1) : '&n bsp;'"
                    :key="index">
                  </div>
                </div>
              </div>
              <div v-if="item.tag == 'RA'" class="flex flex-v-center margin-t">
                <div class="bold mt" style="flex-shrink: 0;">{{ $t('示范音频') }}:</div>
                <wave-player class="margin-l" width="300px"
                  :src="$host + '/api/audio/' + item.no + '/Origin'"></wave-player>
              </div>

              <div v-if="item.tag == 'RL'" class="margin-t">
                <div class="mt bold">关键词：</div>
                <div class="word-mock">{{ item.answer }}</div>
              </div>
            </div>
            <div class="margin-t">

              <div v-if="item.record && item.record.content && item.record.content.replace(/,/g, '')"
                class="margin-t flex">
                <div class="mt bold margin-r" style="margin-top: 3px;">{{ $t('我的答案') }}:</div>
                <record-list :source="item" :answer="item.answer" :tag="item.tag" :mock="true"
                  :list="[item.record]"></record-list>
              </div>

              <div v-else class="st">
                <div>{{ $t('未作答') }}</div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>

        </el-tab-pane>

        <el-tab-pane :label="$t('写作') + '(' + info.items.filter((val) => { return val.sort == 'Writing' }).length + ')'"
          name="Writing">
          <div class="padding-x"
            v-for="( item, index ) in info.items.filter((val) => { return val.sort == 'Writing' }) " :key="index">
            <div class="flex flex-between flex-v-center">
              <h4>{{ index + 1 }}.{{ item.tag }} #{{ item.no }} {{ item.title }} </h4> <el-link size="small"
                @click="toWork(item)" type="primary" class="margin-x">{{ $t('去练习') }}<i
                  class="el-icon-right"></i></el-link>
            </div>



            <div v-if="info.status == 3">

              <div class="mt bold margin-t">{{ $t("题目") }}:</div>
              <div v-if="item.tag == 'SWT'">
                <div class="word-mock" v-for="(row, index) in item.words_en.split('\n')" :key="index">

                  <template v-for="(words, index1) in row.split('^')">
                    <span :key="index1" :class="{ 'high-light1': index1 % 2 == 1 }">{{ words }}</span>
                  </template>
                </div>
              </div>

              <div v-if="item.tag == 'WE'">
                <div class="word-mock ">
                  {{ item.words_en }}
                </div>

                <div class="margin-t">
                  <div v-for="( row, index ) in item.analysis.split('\n') "
                    v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(item.tag)[2] + '：</h4><div>' + getHtml(item, row, 1) + '</div>' : getHtml(item, row, 1) ? getHtml(item, row, 1) : '&n bsp;'"
                    :key="index">
                  </div>
                </div>
              </div>
            </div>

            <div class="margin-t">
              <div v-if="item.record && item.record.content && item.record.content.replace(/,/g, '')" class="margin-t">
                <div class="mt bold margin-t">{{ $t('我的答案') }}:</div>
                <record-list :answer="item.answer" :source="item" :tag="item.tag" :mock="true"
                  :list="[item.record]"></record-list>
              </div>
              <div v-else class="st">
                <div>{{ $t('未作答') }}</div>

              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </el-tab-pane>


        <el-tab-pane :label="$t('阅读') + '(' + info.items.filter((val) => { return val.sort == 'Reading' }).length + ')'"
          name="Reading">
          <div class="padding-x"
            v-for="( item, index ) in info.items.filter((val) => { return val.sort == 'Reading' }) " :key="index">
            <div class="flex flex-between flex-v-center">
              <h4>{{ index + 1 }}.{{ item.tag }} #{{ item.no }} {{ item.title }} </h4> <el-link size="small"
                @click="toWork(item)" type="primary" class="margin-x">{{ $t('去练习') }}<i
                  class="el-icon-right"></i></el-link>
            </div>



            <div v-if="info.status == 3" class=" margin-t">
              <div v-if="item.tag == 'FIB-RW'">
                <div class="flex flex-wrap">
                  <span class="word-mock" v-for="( word, index ) in item.question.split(' ') " :key="index"
                    :class="{ 'high-light2': word.indexOf('|r') != -1, 'high-light1': word.indexOf('|w') != -1, 'high-light': word.indexOf('|e') != -1 }">{{
                      word.replace(/\|[rwe]/g, '') }}</span>
                </div>
              </div>
              <div v-else-if="item.tag == 'FIB-R'">
                <div class="flex flex-wrap">
                  <span class="word-mock" v-for="( word, index ) in item.question.split(' ') " :key="index"
                    :class="{ 'high-light2': word.indexOf('/r') != -1, 'high-light1': word.indexOf('/w') != -1 }">{{
                      word.split('/')[0] }}</span>
                </div>
              </div>

              <div v-else-if="item.tag == 'RO'">

              </div>
              <div class="word-mock" v-else>
                {{ item.words_en.replaceAll("^", "") }}
              </div>



              <div v-if="item.options">
                <div class="line" style="margin: 10px auto;"></div>
                <div v-if="item.question && ['MCS-R', 'MCM-R'].indexOf(item.tag) != -1" class="margin-bs">
                  <div class="word-mock" v-for="( row, index ) in item.question.split('\n') " :key="index">{{ row }}
                  </div>
                </div>

                <div v-if="item.tag == 'FIB-R'" class="word-mock">
                  <el-tag class="margin-r" style="font-size: 16px;" effect="dark"
                    v-for="(words, index) in item.options.split('\n')" :key="'key' + index">
                    {{ words }}
                  </el-tag>
                </div>
                <div v-else>
                  <div class="word-mock" v-for="( option, index ) in item.options.split('\n') " :key="index">{{
                    option }}
                  </div>
                </div>
              </div>
            </div>



            <!--<div class=" margin-t padding" style="background-color: #eee;">
              <div class="bold mt">{{ $t('答案') }}</div>
              <div class="word-mock">{{ item.answer }}</div>

              <div v-if="item.analysis">
                <el-divider></el-divider>
                <div class="bold mt">{{ $t('解析') }} :</div>
                <div class="word-mock">{{ item.analysis }}</div>
              </div>
            </div> -->
            <div class="flex margin-t" v-if="info.status == 3 && item.answer">
              <div class="bold mt" style="flex-shrink: 0;">{{ $t('正确答案') }}:</div>
              <div class="word-mock margin-ls">{{ item.answer.replace(/\^/g, '') }}</div>
              <el-button @click="showWords(item)" v-if="item.tag == 'RO'" class="margin-l" size="mini" round
                type="primary">查看翻译</el-button>
            </div>

            <div class="margin-t">
              <div v-if="item.record && item.record.content && item.record.content.replace(/,/g, '')"
                class="margin-t flex">
                <!-- <div class="bold mt margin-rs" style="flex-shrink: 0;">{{ $t('我的答案') }}:</div> -->
                <record-list :source="item" :answer="item.answer" :tag="item.tag" :mock="true"
                  :list="[item.record]"></record-list>
                <!-- <el-button v-if="item.tag == 'FIB-R'" class="margin-l" size="mini" round type="primary">答案解析</el-button> -->
              </div>
              <div v-else class="st ">
                <div class="flex flex-v-center">
                  <div>{{ $t('未作答') }}</div>
                  <el-button @click="showAnalysis(item)" slot="reference" class="margin-l" size="mini" round
                    type="primary">{{
                      item.tag == "RO" ? $t("顺口溜") : $t("答案解析") }}</el-button>
                </div>

              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </el-tab-pane>

        <el-tab-pane
          :label="$t('听力') + '(' + info.items.filter((val) => { return val.sort == 'Listening' }).length + ')'"
          name="Listening">
          <div class="padding-x"
            v-for="( item, index ) in info.items.filter((val) => { return val.sort == 'Listening' }) " :key="index">
            <div class="flex flex-between flex-v-center">
              <div class="flex flex-v-center">
                <h4>{{ index + 1 }}.{{ item.tag }} #{{ item.no }} {{ item.tag == 'WFD' ? "" : item.title }} </h4>
                <div v-if="info.status == 3" class="margin-l flex flex-v-center">
                  <el-tag size="mini" effect="dark" style="margin-right: 10px;">{{ $t("原音频") }}</el-tag>
                  <wave-player :src="$host + '/api/audio/' + item.no + '/Origin'"></wave-player>
                </div>
              </div>
              <el-link size="small" @click="toWork(item)" type="primary" class="margin-x">{{ $t('去练习') }}<i
                  class="el-icon-right"></i></el-link>
            </div>

            <div v-if="info.status == 3" class="margin-t">
              <div v-if="item.tag != 'WFD'" class="mt bold">{{ $t("音频文本") }}:</div>
              <div v-if="['SST', 'MCM-L', 'HCS', 'MCS-L', 'SMW'].indexOf(item.tag) != -1" class="flex flex-wrap">
                <words-resize-panel :popup="false" :source="item">
                </words-resize-panel>

                <div class="margin-t" v-if="item.tag == 'SST'">
                  <div class="bold mt" style="flex-shrink: 0;">{{ $t('参考答案') }}:</div>
                  <div class="flex flex-wrap">

                    <template v-for="( part, index ) in item.answer.split('^') ">
                      <span class="word-mock"
                        :class="{ 'high-light1': index % 2 == 1, 'symbol': word.replace(/\W/g, '') == '' }"
                        v-for="( word, index1 ) in part.split(' ') " :key="index + '_' + index1">{{ word }}
                      </span>
                    </template>
                  </div>
                </div>
              </div>

              <div v-else-if="item.tag == 'HIW' || item.tag == 'FIB-L'" class="flex flex-wrap">
                <span class="word-mock" v-for="( word, index ) in item.question.split(' ') " :key="index"
                  :class="{ 'high-light': word.indexOf('/') != -1 }">{{ word.split('/')[0] }}</span>
              </div>

              <div v-else class="flex">
                <div class="bold mt margin-rs" v-if="item.tag == 'WFD'">{{ $t("正确答案") }}:</div>
                <div class="word-mock">{{ item.words_en.replace(/\^/g, '') }}</div>
              </div>

            </div>



            <div v-if="info.status == 3 && item.options">
              <div class="line" style="margin: 10px auto;"></div>
              <div v-if="item.question" class="margin-bs">
                <div class="word-mock" v-for="( row, index ) in item.question.split('\n') " :key="index">{{ row }}
                </div>
              </div>
              <div class="word-mock" v-for="( option, index ) in item.options.split('\n') " :key="index">{{ option }}
              </div>
            </div>

            <div class="flex margin-ts"
              v-if="info.status == 3 && item.answer && item.tag != 'SST' && item.tag != 'WFD'">
              <div class="bold mt margin-rs" style="flex-shrink: 0;">{{ $t('参考答案') }}:</div>
              <div class="word-mock">{{ item.answer.replace(/\^/g, '') }}</div>
            </div>

            <div class="margin-ts">
              <div v-if="item.record && item.record.content && item.record.content.replace(/,/g, '')"
                :class="{ 'flex': ['WFD', 'MCM-L', 'FIB-L', 'HCS', 'MCS-L', 'SMW'].indexOf(item.tag) != -1 }">
                <!-- <div class="bold mt margin-rs" style="flex-shrink: 0;">{{ $t('我的答案') }}:</div> -->
                <record-list :answer="item.answer" :source="item" :tag="item.tag" :mock="true"
                  :list="[item.record]"></record-list>
              </div>
              <div v-else class="st">
                <div>{{ $t('未作答') }}</div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </el-tab-pane>

      </el-tabs>

    </el-card>
    <words-resize-panel ref="wordsPanel" v-if="source" :source="source">
    </words-resize-panel>

    <share-dialog v-if="info.status == 3" ref="shareDialog"></share-dialog>
    <analysis-resize-panel ref="analysisPanel" :source="source">
    </analysis-resize-panel>
    <score-dialog ref="scoreDialog"></score-dialog>
    <el-dialog top="20%" custom-class="br" :visible.sync="centerDialogVisible" :close-on-click-modal="false"
      :show-close="false" width="30%" center>
      <h4 slot="title">{{ $t(info.mock.title) }}</h4>
      <div class="center st1">{{ $t("确认进行智能模考打分") }}?</div>
      <span slot="footer" class="dialog-footer center">
        <el-button size="small" @click="centerDialogVisible = false">{{ $t('取 消') }}</el-button>
        <el-button size="small" type="primary" @click="doEvaluate()">{{ $t('确 认') }}</el-button>
      </span>
    </el-dialog>
  </div>
  <div v-else>
    <el-page-header slot="header" @back="goBack"></el-page-header>
    <el-empty></el-empty>
  </div>

</template>

<script>

import * as echarts from 'echarts/core';
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart } from 'echarts/charts';
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent
} from 'echarts/components';
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers';
import WavePlayer from '../../components/WavePlayer.vue'
import scoreDialog from '../../components/ScoreDialog.vue'
import recordList from '../../components/RecordList.vue'
import wordsResizePanel from '../../components/WordsResizePanel.vue'
import analysisResizePanel from '../../components/AnalysisResizePanel.vue'
import shareDialog from '../../components/ShareDialog.vue'

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  MarkLineComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer
]);
export default {
  components: {
    WavePlayer, scoreDialog, recordList, wordsResizePanel, analysisResizePanel, shareDialog
  },
  data() {
    return {
      source: null,
      centerDialogVisible: false,
      sorts: ["Speaking", "Writing", "Reading", "Listening"],
      selectedTab: "0",
      selectedTab1: "Speaking",
      info: null,
      chart: null,
      tableDatas: {},
      chartOpiton: {
        title: {
          text: 'PTEFIGHTER MOCK TEST',
          textStyle: {
            fontSize: 14
          }
        },
        color: ['#921f7a', '#595959', '#d3da48', '#0e3055'],
        tooltip: { show: false },
        grid: {
          left: '20%'
        },
        barCategoryGap: "5px",
        yAxis: {
          triggerEvent: true,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          data: []
        },
        xAxis: {
          show: false,
          min: 0,
          max: 100,
        },
        series: [
          {
            name: 'score',
            type: 'bar',
            data: [],
            colorBy: "data",

            markLine: {
              silent: true,
              symbol: 'none',
              lineStyle: {
                color: '#8592a4',
                type: "solid",
                width: 6
              },
              data: [{
                xAxis: 0,
                name: "Overall",
                label: {
                  distance: [0, 10],
                  formatter: "{a|Overall }{score|{c}}",
                  fontSize: 16,
                  rich: {
                    score: {
                      color: '#000',
                      fontSize: 16,
                      fontWeight: 'bold'
                    }
                  }
                }
              }]
            }
          },
        ]
      }
    };
  },

  activated() {
    if (this.info && this.info.id != this.$route.query.id) {
      this.query()
    }
  },

  created() {
    this.query();
  },

  mounted() {
    this.$bus.$on("show_analysis", (source) => {
      this.showAnalysis(source)
    })
  },

  methods: {
    share() {
      this.$refs.shareDialog.show("分享模考", window.location.href + "&uid=" + this.$store.state.user.id, "复制链接，分享本次模考成绩！");
    },

    showAnalysis(source) {
      this.source = source;
      this.$refs.analysisPanel.show();
    },

    showWords(source) {
      this.source = source;
      this.$refs.wordsPanel.show();
    },
    getHtml(source, row, flag) {
      if (!row) return "<br>";
      if (flag && source.tag != "ASQ") return row;
      let html = `<div class="flex flex-wrap">`;
      let parts = row.split("^");
      parts.forEach((item, index) => {
        if (index % 2 == 1) {
          if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(source.tag) != -1) {
            let words = item.split(" ");
            words.forEach((word) => {

              if (word.replace(/\W/g, "")) {
                html += `<span id="${word}" style="color:red;cursor: pointer;" class="word5">${word}</span>`
              } else {
                if (word == ',') {
                  html += `<span style="color:red;">${word}</span>`
                } else {
                  html += `<span style="color:red;" class="word4">${word}</span>`
                }

              }
            })
          } else {
            html += `<span style="color:red">${item}</span>`
          }
        } else {
          if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(source.tag) != -1) {
            let words = item.split(" ");
            words.forEach((word) => {
              if (word.replace(/\W/g, "")) {
                html += `<span id="${word}" style="cursor: pointer;" class="word5">${word}</span>`
              } else {
                if (word == ',') {
                  html += `<span>${word}</span>`
                } else {
                  html += `<span class="word4">${word}</span>`
                }

              }
            })
          } else {
            html += `<span>${item}</span>`
          }
        }
      })
      html += "</div>";
      return html
    },
    toWork(source) {
      this.$router.push("/main/pte?tag=" + source.tag + "&no=" + source.no)
    },
    remove() {
      this.$confirm(this.$t('是否删除本次模考记录?'), this.$t('提示'), {
        // center: true,
        confirmButtonText: this.$t('确定'),
        confirmButtonClass: "confirm-btn",
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/mock/record/remove", { id: this.info.id }).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('删除成功') + '!'
          });
        })
        this.goBack();
      }).catch(() => {

      });
    },

    goBack() {
      this.$router.back();
    },
    evaluate() {
      if (this.$store.getters.getRole() == 0) {
        this.$vip_alert("此功能");
      } else {
        this.centerDialogVisible = true;
      }
    },
    doEvaluate() {
      this.$http.post("/api/mock/record/evaluate", { id: this.info.id }).then((() => {
        this.info.status = 2
        this.centerDialogVisible = false;
        this.$message.success("提交成功")
      }))
    },
    preview() {
    },

    getWFDData(answer, content) {

      let str1 = answer.replace(/[,.]/g, "").replace(/\s+/g, ' ');
      let str2 = content.replace(/[,.]/g, "").replace(/\s+/g, ' ');

      let _arr1 = str1.split(" ");
      let _arr2 = str2.split(" ");


      let arr1 = [];
      _arr1.forEach((item, index) => {
        if (item.indexOf("^") == 0) {
          _arr1[index] = item.replace("^", "");
          arr1.push(item.replace("^", ""));
        } else {
          arr1.push(item.toLowerCase());
        }
      })
      let arr2 = str2.split(" ");

      let arr = [];
      let index = -1;
      let index2 = -1;

      let item1 = null;
      for (let index1 = 0; index1 < arr1.length; index1++) {
        item1 = arr1[index1];
        if (item1) {
          index = arr2.findIndex((val) => {
            return (item1.split("/").indexOf(val) != -1 || item1.split("/").indexOf(val.toLowerCase()) != -1);
          })

          if (index == -1) {
            arr.push({ word: _arr1[index1], class: "wrong1" });
            arr1[index1] = "";
          } else {
            for (let i = 0; i <= index; i++) {
              if (arr2[i]) {

                index2 = arr1.findIndex((val) => {
                  return (val.split("/").indexOf(arr2[i]) != -1 || val.split("/").indexOf(arr2[i].toLowerCase()) != -1);
                })
                if (index2 == -1) {
                  arr.push({ word: _arr2[i], class: "wrong2" });
                } else {
                  arr.push({ word: _arr2[i], class: "right" });
                  arr1[index2] = "";
                }
                arr2[i] = "";
              }
            }
          }
        }
      }
      for (let j = 0; j < arr2.length; j++) {
        if (arr2[j]) {
          arr.push({ word: arr2[j], class: "wrong2" });
        }
      }
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].class == "wrong1" && (i == 0 || arr[i - 1].class != 'wrong1')) {
          arr[i].word = "(" + arr[i].word;
        }
        if (arr[i].class == "wrong1" && (i == arr.length - 1 || arr[i + 1].class != 'wrong1')) {
          arr[i].word = arr[i].word + ")";
        }

        if (i == arr.length - 1 && content.endsWith(".")) {
          arr[i].word += "."
        }
      }
      return arr;
    },
    showScore(source) {
      let info = { source: source, result: this.info.results[source.index - 1].result, content: this.info.contents[source.index], score: this.info.results[source.index - 1].score };
      this.$refs.scoreDialog.showInfo(info);
    },
    imageUrl(no) {
      return this.$host + '/upload/图片/DI/' + no + '.png'
    },
    query() {

      let id = this.$route.query.id;
      let uid = this.$route.query.uid || 0;
      this.$http.post("/api/mock/record/info", { id: id, uid: uid }).then((info) => {
        this.info = info;
        if (this.info.status == 3) {
          let typeInfo = {}
          this.info.items.forEach((item) => {
            typeInfo[item.tag] = item.sort
            if (item.tag == "HIW") {
              let answers = item.question.match(/(\w+\/\w+)/g)
              answers.forEach((val, index) => {
                answers[index] = val.replace("/", "(") + ")"
              })
              item.answer = answers.join(", ")
            } else if (item.tag == "FIB-RW") {
              let answers = item.answer.split(",");
              let contents = item.record.content.split(',')
              let index = 0;
              item.question = item.question.replace(/\([^)]+\)/g, function (val) {
                let items = val.replace(/[()]/g, '').split('/')
                items.forEach((item, index1) => {
                  let sep = "/"
                  if (index1 == items.length - 1) {
                    sep = ""
                  }
                  item = item.replace(/(^\s+)|(\s+$)/g, '')
                  items[index1] = item;
                  items[index1] = items[index1] + sep

                  if (contents[index] == answers[index]) {
                    items[index1] += "|r"
                  } else {
                    if (item == contents[index]) {
                      items[index1] += "|w"
                    }
                  }
                  if (item == answers[index]) {
                    items[index1] += "|e"
                  }
                })
                index++
                return "(" + items.join(" ") + ")"
              })
            } else if (item.tag == "FIB-R") {
              let answers = item.answer.split(",");
              let contents = item.record.content.split(',')
              let index = 0;
              item.question = item.question.replace(/\(\d\)/g, function () {
                return answers[index++] + "/" + (contents[index - 1] == answers[index - 1] ? 'r' : 'w')
              })
            } else if (item.tag == "FIB-L") {
              let answers = item.answer.split(",");
              let index = 0;
              item.question = item.question.replace(/__/g, function () {
                return answers[index++] + "/"
              })
            } else if (item.tag == 'WFD' && item.record.content) {
              item.record.contents = this.getWFDData(item.answer, item.record.content)
            }
          })
          this.calcScore(typeInfo);
        }
      })
    },
    calcScore(typeInfo) {
      let items = this.info.items;

      this.$http.post("/api/mock/setting").then((info) => {
        let sorts = ["Speaking", "Writing", "Reading", "Listening"];
        let types = ["RA", "RS", "DI", "RL", "ASQ", "SWT", "WE", "FIB-RW", "MCM-R", "RO", "FIB-R", "MCS-R", "SST", "MCM-L", "FIB-L", "HCS", "MCS-L", "SMW", "HIW", "WFD"]
        let weights = [
          [0.38, 0, 0.37, 0],
          [0.4, 0, 0, 0.32],
          [0.15, 0, 0, 0],
          [0.05, 0, 0, 0.06],
          [0.02, 0, 0, 0.02],
          [0, 0.1, 0, 0],
          [0, 0.18, 0, 0],
          [0, 0.2, 0.2, 0],
          [0, 0, 0.02, 0],
          [0, 0, 0.07, 0],
          [0, 0, 0.18, 0],
          [0, 0, 0.01, 0],
          [0, 0.08, 0.06, 0.08],
          [0, 0, 0, 0.02],
          [0, 0.16, 0, 0.1],
          [0, 0, 0.01, 0.01],
          [0, 0, 0, 0.01],
          [0, 0, 0, 0.01],
          [0, 0, 0.08, 0.1],
          [0, 0.28, 0, 0.27],
        ]

        sorts = info.sorts
        types = info.types
        weights = info.weights


        let scores = [];
        types.forEach((_, index) => {
          scores[index] = []
        })

        items.forEach((item) => {
          let score = item.record ? item.record.score : 0;
          if (['RA', 'RS', "RL", "DI"].indexOf(item.tag) != -1) {
            score = score * 0.9
            if (score < 10) {
              score = 10;
            }
          } else if (item.tag == 'ASQ') {
            score = score * 90;
          } else if (item.tag == 'SWT') {
            score = score / 7 * 90;
          } else if (item.tag == 'WE') {
            score = score / 15 * 90;
          } else if (item.tag == 'SST') {
            score = score / 10 * 90;
          } else {
            let len = item.answer.split(/[,\s]/g).length
            console.log(item.tag, item.answer, len)
            if (item.tag == 'RO') {
              len -= 1;
            }
            score = score / len * 90
          }
          scores[types.indexOf(item.tag)].push(score);
        })


        let rates = [];
        let rate = null;
        scores.forEach((score, index) => {
          rate = []
          weights[index].forEach((weight, index1) => {
            if (weight > 0) {
              rate[index1] = 90 * weight / score.length
            } else {
              rate[index1] = 0;
            }
          })
          rates[index] = rate
        })


        let results = [];
        let result = null;
        scores.forEach((score, index) => {
          result = [];
          rates[index].forEach((rate, index1) => {
            result[index1] = (rate * score.reduce((accumulator, currentValue) => accumulator + currentValue, 0)) / 90;
          })
          results[index] = result;
        })
        let data = [];
        results.forEach((result) => {
          result.forEach((val, index) => {
            if (data[index] == undefined) data[index] = 0;
            data[index] += val
          })
        });
        let overall = 0;
        data.forEach((val, index) => {
          overall += (val < 10 ? 10 : val)
          data[index] = val.toFixed(0)
          this.chartOpiton.yAxis.data[index] = {
            value: `${sorts[index]}  {score|${val < 10 ? 10 : val.toFixed(0)}}  `, textStyle: {
              fontSize: 16,
              rich: {
                score: {
                  color: '#000',
                  fontSize: 16,
                  fontWeight: 'bold'
                }
              }
            }
          }
          this.chartOpiton.series[0].data[index] = val < 10 ? 10 : val.toFixed(0)
          this.chartOpiton.series[0].markLine.data[0].xAxis = (overall / 4).toFixed(0)
        });
        this.swapArrayElements(this.chartOpiton.yAxis.data, 0, 1)
        this.swapArrayElements(this.chartOpiton.series[0].data, 0, 1)
        let that = this;
        this.$nextTick(() => {
          this.chart = echarts.init(document.getElementById('chart'));
          this.chart.on("click", function (v) {
            let sort = v.name.replace(/\s+\{.+\}\s+/, '');
            that.selectedTab = (that.sorts.indexOf(sort) + 1).toString();
          })
          this.chart.setOption(this.chartOpiton);
        })


        let tableDatas = [];
        let tableData = null;
        sorts.forEach((sort, index) => {
          tableData = [];
          let score1 = 0;
          let score2 = 0;
          weights.forEach((row1, index1) => {
            row1.forEach((weight, index2) => {
              if (weight > 0 && index2 == index) {
                tableData.push({
                  sort: typeInfo[types[index1]],
                  type: types[index1],
                  weight: (weight * 100).toFixed(0) + "%",
                  score: results[index1][index2].toFixed(1) + " / " + (90 * weight).toFixed(1)
                })

                score1 += results[index1][index2]
                score2 += 90 * weight
              }
            })
          })
          tableData.push({ sort: '总计', type: "", weight: "100%", score: score1.toFixed(1) + ' / ' + score2.toFixed(1) })
          tableDatas.push(tableData);
        })
        this.tableDatas = tableDatas
      })
    },
    swapArrayElements(arr, index1, index2) {
      const temp = arr[index1];
      arr[index1] = arr[index2];
      arr[index2] = temp;
      return arr;
    }
  },

};
</script>

<style scoped>
.word-mock.high-light {
  background-color: yellow;
}

.high-light1,
.word-mock.high-light1 {
  color: red;
}

.word-mock.high-light2 {
  color: green;
}

.mock-record>>>.el-tabs__item {
  line-height: 10px !important;
  font-size: 16px;
  color: #aaa;
}


.mock-record>>>.el-tabs__item.is-active {
  color: #e6a13c;
}

.mock-record>>>.el-card__header .el-page-header__content {
  width: 0;
  flex: 1;
}


.mock-record>>>.el-card__header .el-page-header__left {
  align-items: center;
}

.mock-record>>>.end {
  line-height: 30px;
}

.mock-record>>>.symbol {
  padding-left: 0;
  padding-right: 0;
}
</style>
