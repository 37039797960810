<template>
    <transition name="el-fade-in-linear">
        <VueDragResize tabindex="0" contenteditable="true" ref="drag" @activated="onActivated"
            @deactivated="onDeactivated" v-if="isShow" class="fix-center" :class="source.sort" style="position:fixed"
            :z="2000" :minw="300" :minh="300" :w="width" :h="height" :isActive="true" v-on:resizing="resize">
            <el-card v-if="source" class="drag-resize" style="cursor:move;border-radius: 10px;"
                :style="{ height: height + 'px', width: width + 'px' }">
                <h3 slot="header" class="ellipsis">{{ '#' + source.no + ' ' + source.title }}</h3>

                <div class="mt" :style="{ height: (height - marginTop) + 'px' }"
                    style="overflow-y: scroll;overflow-x: hidden;background: #f0f0f0;padding:0 10px;">
                    <div v-for="( row, index ) in  source.analysis.split('\n') "
                        v-html="index == 0 ? '<h4>' + $t('答案解析') + '：</h4><div>' + getHtml(source, row, 1) + '</div>' : getHtml(source, row, 1) ? getHtml(source, row, 1) : '&n bsp;'"
                        :key="index">
                    </div>
                </div>

                <div class="right margin-t">
                    <!-- <span class="st">提示：此显示框可以拖动位置，可以缩放哦</span> -->
                    <el-button round type="primary" @click="isShow = false">{{ $t("关闭") }}</el-button>
                </div>
            </el-card>
        </VueDragResize>
    </transition>

</template>

<script>
import VueDragResize from 'vue-drag-resize'
export default {
    components: {
        VueDragResize
    },
    name: "analysisResizePanel",
    data() {
        return {
            width: 700,
            height: 500,
            isShow: false,
            left: 0,
            marginTop: 140,
        };
    },
    props: {

        source: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },


    methods: {
        getHtml(source, row, flag) {
            if (!row) return "<br>";
            if (flag && source.tag != "ASQ") return row;
            let html = `<div class="flex flex-wrap">`;
            let parts = row.split("^");
            parts.forEach((item, index) => {
                if (index % 2 == 1) {
                    if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(source.tag) != -1) {
                        let words = item.split(" ");
                        words.forEach((word) => {

                            if (word.replace(/\W/g, "")) {
                                html += `<span id="${word}" style="color:red;cursor: pointer;" class="word5">${word}</span>`
                            } else {
                                if (word == ',') {
                                    html += `<span style="color:red;">${word}</span>`
                                } else {
                                    html += `<span style="color:red;" class="word4">${word}</span>`
                                }

                            }
                        })
                    } else {
                        html += `<span style="color:red">${item}</span>`
                    }
                } else {
                    if (['RS', 'RL', 'ASQ', 'SWT', 'WE', 'SST', 'WFD'].indexOf(source.tag) != -1) {
                        let words = item.split(" ");
                        words.forEach((word) => {
                            if (word.replace(/\W/g, "")) {
                                html += `<span id="${word}" style="cursor: pointer;" class="word5">${word}</span>`
                            } else {
                                if (word == ',') {
                                    html += `<span>${word}</span>`
                                } else {
                                    html += `<span class="word4">${word}</span>`
                                }

                            }
                        })
                    } else {
                        html += `<span>${item}</span>`
                    }
                }
            })
            html += "</div>";
            return html
        },

        onActivated() {
        },

        onDeactivated() {
            if (!this.$store.state.wordDialogShow) {
                this.isShow = false;
            }
        },

        show() {
            this.height = 500;
            this.width = 700;
            this.isShow = true;
        },

        hide() {
            this.isShow = false;
        },

        resize(res) {
            this.width = res.width;
            this.height = res.height;
        },


    },
};
</script>

<style scoped>
.Speaking>>>.el-card__header {
    color: #fff;
    background: #de7d89;
}

.Speaking>>>.el-tag--primary {
    background: #de7d89 !important;
}



.Writing>>>.el-card__header {
    color: #fff;
    background: #796f85;
}


.Writing>>>.el-tag--primary {
    background: #796f85 !important;
}




.Reading>>>.el-tag--primary {
    background: #d5795d !important;
}


.Reading>>>.el-card__header {
    background: #d5795d;
    color: #fff;
}

.Listening>>>.el-card__header {
    background: #86a6c5;
    color: #fff;
}

.Listening>>>.el-tag--primary {
    background: #86a6c5 !important;
}

.br1 {
    color: red;
    /* font-weight: bold; */
}

.fix-center {
    margin-top: calc(50vh - 250px);
    margin-left: calc(50vw - 350px);
}

.drag-resize>>>.el-card__body {
    padding-top: 5px;
}

.end {
    margin-left: -2px;
    line-height: 20px;
}
</style>